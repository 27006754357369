<template>
  <div>
    <nav2></nav2>
    <banner2 :bannerH="bannerH" :bannerP="bannerP"
             :bannerImg="require('@/assets/img/product/banner.jpg')"></banner2>
    <div class="content-container">
      <router-view />
    </div>
    <footer2></footer2>
  </div>
</template>
<script>
import nav2 from "@/components/nav.vue";
import banner2 from "@/components/banner.vue";
import footer2 from "@/components/footer.vue";
import router from "@/router";

export default {
  name: 'product',
  components: {
    footer2,
    nav2,
    banner2
  },
  watch: {
    $route() {
      let meta = router.currentRoute.meta;
      this.bannerH = meta.title;
    }
  },
  data() {
    return {
      bannerH: "产品线",
      bannerP: "Product Line",
      bannerImg: "http://www.safeluck.com/images/banner/inner-4.jpg"
    }
  },
}
</script>
<style scoped lang="less">
.content-container {
  min-width: 1000px;
  max-width: 1190px;
  margin: 0 auto;
  padding: 40px 0;
}
</style>
